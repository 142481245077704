@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
:root {
  --background-green: linear-gradient(
    164.04deg,
    #6bc6b4 7.65%,
    #127358 123.79%
  );
  --light-green: #77cfa1;
  --secondry-green: linear-gradient(104.8deg, #6bc6b4 5.91%, #2f9480 197.29%);
}
p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
body {
  font-family: "Poppins", sans-serif;
}
.green_background_linear {
  background: var(--background-green);
}
.green_color_linear {
  background-image: linear-gradient(164.04deg, #6bc6b4 7.65%, #127358 123.79%);
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  color: transparent;
}
.button {
  padding: 0.5rem 0.8rem;
  background: var(--background-green);
}
.button:hover {
  background: var(--secondry-green) !important;
  transform: scale(1.03);
  transition: all 0.5s ease-in-out;
}
.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagecss {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.card_background {
  background: linear-gradient(104.8deg, #6bc6b4 5.91%, #2f9480 197.29%);
}
.box_shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.green_gredient {
  background: var(--secondry-green);
}
.backgroundPosition {
  background-position-x: right;
  background-position-y: center;
}
.css-1wnsr1i,
.css-111ci61,
.css-qrdfw9 {
  top: 22% !important;
  left: 25% !important;
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.scroll_bar::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  border-radius: 8px;
  cursor: pointer;
}

/* Track */
.scroll_bar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle */
.scroll_bar::-webkit-scrollbar-thumb {
  background: #6bc6b4; /* Color of the scrollbar handle */
}

/* Handle on hover */
.scroll_bar::-webkit-scrollbar-thumb:hover {
  background: #0a9a73; /* Color of the scrollbar handle on hover */
}

.loader {
  width: 120px;
  height: 24px;
  -webkit-mask: radial-gradient(circle closest-side, #000 94%, #0000) 0 0/25%
      100%,
    linear-gradient(#000 0 0) center/calc(100% - 12px) calc(100% - 12px)
      no-repeat;
  background: linear-gradient(#25b09b 0 0) 0/0% no-repeat #ddd;
  animation: l7 2s infinite linear;
}
@keyframes l7 {
  100% {
    background-size: 100%;
  }
}
#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  left: 0 !important;
}
.menu__btn {
  position: fixed;

  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1;
  right: 3rem;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #616161;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 60px 0;
  list-style: none;
  background-color: #eceff1;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.25s;
  z-index: 1;
}
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
}
.menu__item:hover {
  background-color: #cfd8dc;
}
.z_index {
  z-index: 999999999;
}
@media only screen and (max-width: 639px) {
  .scroll_x {
    overflow: hidden;
    overflow-x: scroll;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .css-1wnsr1i,
  .css-111ci61,
  .css-qrdfw9 {
    top: 15% !important;
    left: 10% !important;
  }
}
.loginFormError {
  font-size: 12px;
  color: rgb(249, 44, 44);
}
.contact_shadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
